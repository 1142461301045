<template>
    <div class="container ">
        <div id="Header" class="col-sm-12">
            <div class="row" style="align-items: baseline">
                <div class="col-sm-3">
                    <h6>
                        Gesamt Dauer: {{ formatTime(currentTotalTime) }}
                    </h6>
                </div>
                <div class="col-sm-3">
                    <h6>
                        Anzahl Benutzer: {{ $store.state.roomState.clients ? $store.state.roomState.clients.length : 0
                        }}
                    </h6>
                </div>
                <div class="col-sm-2">
                    <h6>
                        Freigegeben: {{ freeTracksCount }}
                    </h6>
                </div>
                <div class="col-sm-2">
                    <h6>
                        Zur Freigabe:
                        <router-link style="color: #01b301" to="/waitlist">{{ lockedTracksCount }}</router-link>
                    </h6>
                </div>

                <div class="col-sm-2" style="width: auto; text-align: right">
                </div>
            </div>
        </div>
        <h2>Playlist</h2>
        <div class="c-grid-block" v-if="statusLoaded">
            <table class="table table-hover">
                <thead class="thead-dark2">
                <tr>
                    <th scope="col">Titel</th>
                    <th scope="col">Interpret</th>
                    <th scope="col">Album</th>
                    <th scope="col">Hinzugefügt von</th>
                    <th scope="col">
                        <div class="text-right">
                            Votes
                        </div>
                    </th>
                    <th scope="col">
                        <div class="text-right">
                            Aktionen

                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="trackEntry in sortedQueue" v-if="!trackEntry.locked_until">
                    <td scope="row">
                        <strong>{{ trackEntry.track.name }}</strong>&nbsp;
                        <span v-if="trackEntry.locked_until">Gesperrt bis {{formatDate(trackEntry.locked_until) }}</span>
                    </td>
                    <td>{{ trackEntry.track.album.artist.name }}</td>
                    <td>{{ trackEntry.track.album.name }}</td>
                    <td>
                        <span v-if="trackEntry.client_id">{{ getClientName(trackEntry.client_id) }}</span>
                        <span v-else>Host</span>
                    </td>
                    <td>
                        <div class="text-right">
                            <span v-if="trackEntry.votes === 9999999">Now Playing</span>
                            <span v-else-if="trackEntry.votes === 9999998">Up Next</span>
                            <span v-else>{{ trackEntry.votes }}</span>
                        </div>
                    </td>
                    <td>
                        <div class="text-right">
                            <a class="btn btn-secondary btn-sm" @click="vote(trackEntry.entry_id, 1)">
                                <i class="fas fa-plus"></i>
                            </a>
                            <a class="btn btn-secondary btn-sm" @click="vote(trackEntry.entry_id, -1)">
                                <i class="fas fa-minus"></i>
                            </a>
                            <a class="btn btn-secondary btn-sm dropdown-toggle" href="#" role="button"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Aktionen
                            </a>
                            <div class="dropdown-menu">
                                <a class="dropdown-item" href="#" @click.prevent="removeTrack(trackEntry)">
                                    <i class="fas fa-trash"></i>
                                    Track löschen</a>
                                <a class="dropdown-item" href="#" @click.prevent="banTrack(trackEntry)">
                                    <i class="fas fa-ban"></i>
                                    Track bannen</a>
                                <a class="dropdown-item" href="#" @click.prevent="vote(trackEntry.entry_id, 100)">
                                    <i class="fas fa-plus"></i>
                                    100 Votes</a>
                                <a class="dropdown-item" href="#" @click.prevent="vote(trackEntry.entry_id, -100)">
                                    <i class="fas fa-minus"></i>
                                    100 Votes</a>
                            </div>
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <h2>Bereits gespielte Songs</h2>
        <div class="c-grid-block" v-if="statusLoaded">
            <table class="table table-hover">
                <thead class="thead-dark2">
                <tr>
                    <th scope="col">Titel</th>
                    <th scope="col">Interpret</th>
                    <th scope="col">Album</th>
                    <th scope="col">Hinzugefügt von</th>
                    <th scope="col">Wieder verfügbar ab</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="trackEntry in sortedQueue" v-if="trackEntry.locked_until">
                    <td scope="row">
                        <strong>{{ trackEntry.track.name }}</strong>&nbsp;
                    </td>
                    <td>{{ trackEntry.track.album.artist.name }}</td>
                    <td>{{ trackEntry.track.album.name }}</td>
                    <td>
                        <span v-if="trackEntry.client_id">{{ getClientName(trackEntry.client_id) }}</span>
                        <span v-else>Host</span>
                    </td>
                    <td>
                        {{formatDate(trackEntry.locked_until) }}
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import dateformat from 'dateformat'

    export default {
        name: 'Queue',
        components: {},
        computed: {
            currentTrack: function () {
                return this.$store.state.roomState.currentTrack
            },
            currentTotalTime: function () {
                var time = 0

                if (this.$store.state.roomState.queue) {
                    this.$store.state.roomState.queue.forEach(el => {
                        time += el.track.duration
                    })
                }

                return time
            },
            lockedTracksCount: function () {
                return this.$store.state.roomState.locked_tracks ? this.$store.state.roomState.locked_tracks : 0
            },
            freeTracksCount: function () {
                return this.$store.state.roomState.queue ? this.$store.state.roomState.queue.length : 0
            }
        },
        methods: {
            formatDate (date) {
                return dateformat(date, "HH:MM")
            },
            async banTrack (entry) {
                await axios.post('/host/track', {
                    method: 'ban',
                    entry_id: entry.entry_id
                })

            },
            async removeTrack (entry) {
                await axios.post('/host/track', {
                    method: 'remove',
                    entry_id: entry.entry_id
                })

            }
        }
    }
</script>

<style scoped>
    .table {
        margin-top: 12px;
    }

    .table-hover tbody tr:hover td {
        background-color: rgba(69, 153, 55, 0.2);
    }

    .thead-dark2 {
        background-color: rgba(69, 153, 55, 0.2);
    }

    .c-grid-block {
        background-color: rgba(126, 186, 118, 0.1);
        color: #000000;
        /* box-shadow: 0 0.2rem 0.5rem 0 rgba(0, 0, 0, 0.1), 0 0.3rem 0.1rem 0 rgba(0, 0, 0, 0.05);
        */
    }

</style>

